:root {
  --theme-font: 'Poppins', sans-serif;
  --theme-logo-font: 'Inknut Antiqua', serif;
  --theme-color: white;
  --gray-600: #475569;
  --gray-700: #344054;
  --dark-blue-color: #30a1ff;
}

a {
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: var(--theme-font);
  color: var(--gray-600);
  font-size: 16px;
  font-weight: 400;
}

.btn {
  padding: 10px 16px;
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-top: 10px;
} */

.logo {
  color: var(--dark-blue-color);
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login form {
  border: 1px solid transparent;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0px 6px 44px rgba(46, 112, 159, 0.16);
}

.btn-bg-color {
  background-color: var(--dark-blue-color) !important;
}

.custom-button {
  line-height: 12px !important;
  background-color: var(--dark-blue-color) !important;

}

.qr-text {
  /* width: 36.938rem; */
  line-height: normal;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
}

.sticky-content {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--dark-blue-color);
  padding: 1rem 70px;
}

.sticky-content-title {
  margin: 0;
  color: #fff;
}

.logout-btn {
  padding: 3rem 70px;
  font-family: var(--theme-font);
  font-size: 22px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

.booking-detail {
  font-family: var(--theme-font);
  padding: 0 70px;
}

.arrived {
  padding: 10px 30px;
}

.boking-detail-head {
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
}

/* .qr-scanner{
  width: 50% !important;
} */
.title {
  color: #7D7D7D;
  line-height: 26px !important
}

.scanner svg path {
  stroke: rgba(0, 0, 0) !important;
  stroke-width: 4 !important;

}

.scaner-head {
  padding: 0 70px;
}

.custom-border {
  border-bottom: 1px solid #f0ecec;
}

.form-control {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}

@media screen and (max-width: 767px) {
  .sticky-content {
    padding: 15px 24px;
  }

  .sticky-content .btn {
    filter: brightness(0) invert(1);
  }

  .custom-border {
    border: none;
  }

  .scaner-head {
    padding: 0 20px;
    border-bottom: 1px solid #f0ecec;
  }

  .booking-detail {
    padding: 0 12px;
  }

  .booking-detail .title {
    font-size: 16px !important;
  }

  .booking-detail .fs-4 {
    font-size: 18px !important;
  }

  .boking-detail-head {
    padding: 12px 23px 8px 23px;
  }

  .boking-detail-head::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    bottom: 0;
    background: #E8E8E8;
  }

}